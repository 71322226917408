import { db } from "../../config/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";

export const checkUser = async (user) => {
  const userRef = doc(db, "Users", user.uid);
  const docSnap = await getDoc(userRef);

  if (!docSnap.exists()) {
    try {
      await setDoc(userRef, {
        Credits: 0,
        Email: user.email,
        Name: user.displayName,
        Plan: "none",
        UserID: user.uid,
        UsedCredits: 0,
      });

      // console.log("User Created Successfully");
    } catch (error) {
      console.error("Error creating user document:", error);
    }
  } else {
    console.log("data from Users Collection : ", docSnap.data());
    console.log("User already exists in Users Collection");
  }
};
