import { CirclesWithBar } from "react-loader-spinner";
import LoginForm from "../../components/loginFrom/LoginForm";
import "./login.scss";
import { useEffect, useState } from "react";
import Title from "../../components/title/Title";

const Login = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 400);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="login">
      {/* Title of the document */}
      <Title title="Login - AsistAI" />
      {isLoading ? (
        <div
          className="w-100 d-flex align-items-center justify-content-center"
          style={{ height: "100vh" }}
        >
          <CirclesWithBar
            height="100"
            width="100"
            color="#1492d8"
            outerCircleColor="#1492d8"
            innerCircleColor="#1492d8"
            barColor="#1492d8"
            ariaLabel="circles-with-bar-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <LoginForm />
      )}
    </div>
  );
};

export default Login;
