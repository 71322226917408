import React, { useEffect, useState, Suspense, lazy } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { CirclesWithBar } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import AuthContextProvider from "./context/AuthContext";
import PrivateRoute from "./PrivateRoute";
import emailjs from "@emailjs/browser";
import "./App.css";
import "./animate.css";
import "react-toastify/dist/ReactToastify.css";

// Lazy-loaded components
const Home = lazy(() => import("./pages/home/Home"));
const Login = lazy(() => import("./pages/login/Login"));
const Signup = lazy(() => import("./pages/signup/Signup"));
const ForgotPassword = lazy(() =>
  import("./pages/forgot-password/ForgotPassword")
);
const Dashboard = lazy(() => import("./pages/dashboard/Dashboard"));
const Contact = lazy(() => import("./pages/contact/Contact"));
const PrivacyPolicy = lazy(() => import("./pages/privacyPolicy/PrivacyPolicy"));
const TermsAndConditions = lazy(() =>
  import("./pages/termsAndConditions/TermsAndCondition")
);
const PlanPage = lazy(() => import("./pages/planPage/PlanPage"));

function App() {
  const { i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const checkUserLocation = async () => {
      try {
        const response = await axios.get(
          `https://ipinfo.io/json?token=${process.env.REACT_APP_IP_INFO_API_TOKEN}`
        );
        const data = response.data;
        if (data.country === "ES") {
          console.log("Spain");
          i18n.changeLanguage("es");
        } else {
          i18n.changeLanguage("en");
          console.log("Not Spain");
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching IP location data: ", error);
        i18n.changeLanguage("en"); // Default to English in case of an error
      }
    };

    checkUserLocation();
  }, [i18n]);

  emailjs.init({
    publicKey: process.env.REACT_APP_PUBLIC_KEY,
    // Do not allow headless browsers
    blockHeadless: true,
    limitRate: {
      // Set the limit rate for the application
      id: "app",
      // Allow 1 request per 10s
      throttle: 10000,
    },
  });

  return (
    <AuthContextProvider>
      <div className="App">
        <Suspense
          fallback={
            <div
              className="w-100 d-flex align-items-center justify-content-center"
              style={{ height: "100vh" }}
            >
              <CirclesWithBar
                height="100"
                width="100"
                color="#1492d8"
                outerCircleColor="#1492d8"
                innerCircleColor="#1492d8"
                barColor="#1492d8"
                ariaLabel="circles-with-bar-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          }
        >
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-conditions" element={<TermsAndConditions />} />
            <Route path="/reset-password" element={<ForgotPassword />} />
            <Route path="/plans" element={<PlanPage />} />
            <Route
              path="/dashboard/*"
              element={<PrivateRoute Component={Dashboard} />}
            />
          </Routes>
        </Suspense>

        <ToastContainer
          position="top-center"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          pauseOnHover
          theme="dark"
        />
      </div>
    </AuthContextProvider>
  );
}

export default App;
